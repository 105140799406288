<template>
  <LazyDrawer position="right" :is-open="dropshipTray" :hide-scroll="false">
    <template #header>
      <Stack tag="div" align="start" justify="end">
        <button @click="closeTray()" class="text-charcoal-default justify-self-end">
          <Icon name="plus-thick" filled class="text-charcoal-default rotate-45" :size="16" />
        </button>
      </Stack>
      <Heading tag="h3" size="xs" class="mt-xs">Trusted supplier deliveries</Heading>
    </template>

    <aside class="pt-md h-full max-h-screen">
      <Stack class="h-full" align="start" justify="between" direction="col">
        <Text class="px-[24px]"
          >Delivered directly to you (within mainland UK) from our trusted suppliers, usually within 7 working days. See
          our
          <NuxtLink to="/policies/terms-and-conditions" class="text-mkm-blue-light cursor-pointer">T&C’s </NuxtLink>for
          further information.
        </Text>
        <Stack
          class="py-[24px] px-[24px] w-full"
          align="center"
          justify="center"
          style="box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25)"
        >
          <Button variant="neutral" size="md" class="!w-full justify-center" @click="closeTray">CANCEL</Button>
        </Stack>
      </Stack>
    </aside>
  </LazyDrawer>
</template>

<script setup lang="ts">
const { setDropshipTray, dropshipTray } = useUIState();

const closeTray = () => {
  setDropshipTray();
};
</script>
